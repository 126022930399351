import React from 'react'
import styles from './PageTitle.module.scss'
import Container from '../Layout/Container'

const PageTitle = ({ title, children }) => (
  <div className={styles.pageTitle}>
    <Container>
      <h1 className={styles.header}>{title}</h1>
      <span className={styles.subtext}>{children}</span>
    </Container>
  </div>
)

export default PageTitle
