import React from 'react'
import { graphql } from 'gatsby'
import styles from './support-page.module.scss'

// Components
import Layout from '../components/Layout'
import Section from '../components/Layout/Section'
import PageTitle from '../components/Elements/PageTitle'

export const SupportPageTemplate = ({
  title,
  product,
  html
}) => {
  return (
    <article>
      <PageTitle title={product}>
        Product documentation and frequently asked questions.
      </PageTitle>
      <Section>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
    </article>
  )
}

const SupportPage = ({ data }) => {
  const { markdownRemark: { html, frontmatter: article } } = data
  return (
    <Layout className={styles.blogIndex}>
      <SupportPageTemplate title={article.title} html={html} product={article.product} />
    </Layout>
  )
}

export default SupportPage

export const pageQuery = graphql`
  query SupportPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        templateKey
        product
      }
    }
  }
`;